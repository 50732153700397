button, a.button, .button {
    @apply px-8 py-4 text-grey-darkest bg-yellow-lightest no-underline font-bold;
    transition: all 0.25s ease;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 1px 2px rgba(255,255,255,0.5);
        @apply bg-yellow-lighter text-black;
    }
}

.button-small {
    @apply text-sm px-4 py-2 border;
}
