@tailwind preflight;

@tailwind components;

@import "common/common";

// global styles on the most common elements
html {
    @apply font-sans leading-normal text-black;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-serif;
}

a {
    @apply text-blue-primal no-underline;
    &:hover {
        transition: all 0.25s ease;
        @apply underline;
    }
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.w-content {
    @apply max-w-90 mx-auto;

    @screen xl {
        @apply max-w-2xl;
    }
}

.w-content-narrow {
    @apply max-w-90 mx-auto;
    @screen md {
        @apply max-w-md;
    }
    @screen xl {
        @apply max-w-md;
    }
}

.w-content-copy {
    @apply max-w-90 mx-auto;
    @screen md {
        @apply max-w-sm;
    }
    @screen xl {
        @apply max-w-lg;
    }
}

// common styles
@import "common/alerts",
    "common/content_alignment",
    "common/forms",
    "common/modal";

// public only styles
@import "public/content",
    "public/header",
    "public/herobar",
    "public/buttons",
    "public/contact",
    "public/services",
    "public/gallery";

// page specific
@import "public/p_locations";

@tailwind utilities;
