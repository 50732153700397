.location-bg {
    // background-image: url('/images/penticton.jpg');
    @apply bg-cover bg-center w-80 mx-auto;
}

.location-map_wrap {
    .cmsmap {
        @apply mx-auto;
        @screen lg {
            @apply mx-0;
        }
    }
}
