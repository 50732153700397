.herobar {
    @apply h-64;
    @screen 2xl {
        @apply h-80;
    }
}

.herobar-hp {
    background-image: url('/images/herobar-hp.jpg');
    @apply h-100 bg-cover bg-center;
    @screen 2xl {
        @apply h-120;
    }
}

.herobar-contact {
    @apply bg-cover bg-center;
    background-image: url('/images/herobar-contact.jpg');
}

.banner {
    @apply h-100 bg-cover bg-center;
    @screen xl {
        @apply h-120;
    }
}

.banner-hp {
    @apply bg-center;
    background-image: url('/images/generator.jpg');
}
.banner-hp-bottom {
    @apply h-64 bg-scroll;

    @screen 2xl {
        @apply h-80;
    }
    background-image: url('/images/okanagan-electricians.jpg');
}
