.bg-pattern {
    background: rgb(34,41,47) url('/images/bkg.jpg') repeat;
    @apply text-white;
    a {
        @apply text-white;
    }
}

.bg-pattern-light {
    background: rgb(218, 219, 213) url('/images/bkg-light.jpg') repeat;
}

.text-shadow {
    text-shadow: 0 1px 2px rgba(0,0,0,0.20);
    &-lg {
        text-shadow: 0 2px 4px rgba(0,0,0,0.40);
    }
}