.header {
    background: rgb(34,41,47) url('/images/bkg.jpg') repeat;
    @apply font-serif text-white;
}

.header-nav_item {
    @apply hidden;

    @screen lg {
        @apply inline-block mr-12;
    }
    a {
        @apply pb-1 text-white no-underline border-b border-transparent;
        transition: all 0.25s ease;
        &:hover {
            @apply text-yellow-lighter border-yellow-lighter no-underline;
        }
    }
    &-contact {
        @apply mr-16;
        @screen xs {
            display: inline-block;
        }
        @screen md {
            @apply mr-0;
        }
    }
    &-services {
        @screen sm {
            @apply inline-block mr-12;
        }
    }
    &-locations {
        @screen md {
            @apply inline-block mr-12;
        }
    }
    &-gallery {
        @screen lg {
            @apply inline-block mr-12;
        }
    }
}

.header-menu-icon {
    position: absolute;
    right: 0;
    bottom: 0;
}

.header_mobile-open {
    @apply block;

    nav {
        @apply relative block w-full pt-6 pb-4;
    }
    .header-nav-wrap {
        @apply block ;
    }
    .header-nav_item {
        @apply block mb-3 text-center;
        &-contact {
            @apply mr-0 mb-0;
        }
        &-services, &-locations, &-gallery {
            @screen sm {
                @apply mr-0;
            }
        }
    }
    .header-menu-icon {
        top: -0.4rem;
    }
}
