#silentbox-group {
    text-align: center;
}

#silentbox-overlay {
    z-index: 9999 !important;
}

.gallery {
    @apply hidden;
}

.gallery-open {
    @apply block;
}